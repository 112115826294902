import React, {Component} from 'react';

import { TransitionGroup,CSSTransition } from 'react-transition-group';
import './FadeStyles.css';

class Fade extends Component {

  render() {    
    return (
        <React.Fragment>
            <CSSTransition in={this.props.show} timeout={300} classNames="fade" unmountOnExit>
                {this.props.children}
            </CSSTransition>
        </React.Fragment>
    );
  }
}

export default Fade;
