import * as firebase from 'firebase'

const configProduction = {
    apiKey: 'AIzaSyCuWMjJ8Joc4P7Dr-YlwL_QcYNekQmMNNo',
    //authDomain: 'react-firebase-94af6.firebaseapp.com',
    databaseURL: 'https://dh200-fbc80.firebaseio.com',
    storageBucket: 'dh200-fbc80.appspot.com',
    //messagingSenderId: '7968**********'
}

const configTest = {
    apiKey: 'AIzaSyBBm09avfrzFLg3TqMSPime-thsqr4Jm1k',
    //authDomain: 'react-firebase-94af6.firebaseapp.com',
    databaseURL: 'https://dh200-test-default-rtdb.firebaseio.com',
    storageBucket: 'dh200-test.appspot.com',
    //messagingSenderId: '7968**********'
}

const config = configProduction;


class firebaseController {

    constructor() {
        firebase.initializeApp(config);        
        //Si un usuario nuevo se crea entonces este se loguea automaticamente antes 
        //de poder guardar los datos de detalle, entonces si un usuario no tiene detalle en
        //firebase aun mira esta variable que se setea al crear la cuenta
        this.newUserDetails = {};
    }

    init = (app) => {
        this.app = app;

        firebase.auth().onAuthStateChanged((user) => {
            console.log('INT Llama auth con',user);
            
            if (user) {
                this.user = user;                
                // User is signed in.
            } else {
                this.user = null;
                // No user is signed in.
            }
        
            this.app.authChange(user);
        });
    }
    
    createAccount = (data,fnEnd) => {
        
        this.newUserDetails = {name: data.nombre, email: data.email, tipoFruto: data.tipoFruto};

        firebase.auth().createUserWithEmailAndPassword(data.email, data.password)
          .then((res) => {
              console.log('Llega fire con',res);
              res.user.updateProfile({
                  displayName: data.nombre,
              }).then(() => {
                  console.log('us1',res);
                  console.log('us2',res.user);
                  console.log('us3',res.user.uid);
                firebase.database().ref('users/' + res.user.uid).set({
                    name: data.nombre,
                    email: data.email,
                    tipoFruto: data.tipoFruto
                }).then(() => {this.newUserDetails = {}})
                fnEnd();              
              })
          })
          .catch((error) => {        
            //var errorCode = error.code;
            //var errorMessage = error.message;
            this.newUserDetails = {}
            error = this.translateError(error);
            console.log('Get',error.message);
            fnEnd(error);
        });
    }

    login = (email,pass,callback) => {
        console.log('Trata de loguear con ',email,pass);
        firebase.auth().signInWithEmailAndPassword(email,pass).then((res) => {      
          console.log('Login en firebase con',res);
          callback({user:res});
        }).catch(function(error) {
            console.log('Error login firebase',error);            
            callback({error:true,errorMessage:error.message,errorCode:error.code});            
        });
    }

    getUserDetails = async (user) => {
        return new Promise((resolve,reject) => {
            firebase.database().ref("users/" + user.uid ).once("value", (snapshot) => {
                console.log('Llega details',snapshot.val());                      
                if(snapshot.val()==null) {
                    resolve(this.newUserDetails);
                }
                else {
                    resolve(snapshot.val());
                }
            }, (errorObject) => {
              reject(errorObject);
            });
        })
    }

    getUserData = (user,callback) => {

        this.callback = callback;

        var key = user.email.replace(".","_") + "/data";

        console.log('Leer key',key);
        firebase.database().ref(key).on("value", (snapshot) => {
            console.log('Llega la data',snapshot.val());            
            var val = snapshot.val();
            if(!val) val = [];
            this.callback(val);
        }, (errorObject) => {
            this.callback({error:true,errorObject});
        });        
    }

    getUserLotes = (user) => {

        return new Promise((resolve) => {
            var key = user.email.replace(".","_") + "/lotes";
    
            console.log('Leer key lotes',key);
            firebase.database().ref(key).on("value", (snapshot) => {
                console.log('Llega la data lotes',snapshot.val());            
                var val = snapshot.val();
                if(!val) val = [];
                resolve(val);
            }, (errorObject) => {
                resolve({error:true,errorObject});
            });        
        })
    }

    closeSession = () => {
        firebase.auth().signOut().then(() => {
            this.app.authChange(null);
        }).catch(function(error) {
            console.log('Error al desloguear',error);            
        });
    }

    translateError = (error) => {
        console.log('ERROR',"<"+error.code+">");
        switch (error.code) {
          case "auth/email-already-in-use":
            error.message = "Ya existe una cuenta con ese correo electrónico.";        
            break;
    
          case "auth/weak-password": 
            error.message = "La contraseña es demasiado débil, debe tener al menos 6 caractéres.";   
            break;
        
          default:
            return error;        
        }
    
        return error;
    }
}

export let firebaseCtr = new firebaseController();