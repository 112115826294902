import React, {Component} from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';

import logo from '../res/img/logo.png'; // with import

import { withContext } from '../context/AppContext';

var is = require('is_js');

//import FwModalLoader from './FwUtils/FwModalLoader';
//import FwModalWindow from './FwUtils/FwModalWindow';
//import AppBarAdmin from "./AppBarAdmin";



const styles = theme => ({ 
  mainCointainer: {
    display: "flex",
    flexDirection: "column",
    flex: "1",
  },

  divContent: {
    display: "flex",
    padding: "20px",
    borderRadius: "4px",
    justifyContent: "center",
    //backgroundColor: "#eeeeee",    
    flex: 1,
  },

  paper: {
    ...theme.mixins.gutters(),
    padding: theme.spacing(4),    
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  imgLogo: {
    objectFit: "contain",
    height: '10%',
    marginTop: '10%',
    minHeight: '70px',
  },

  formContainer: {
    marginTop: theme.spacing(),
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },

  button: {
    marginTop: theme.spacing(3),
  }, 

  createContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    fontSize: '1.2em',
    alignItems: 'center',
  },

  createAccountLink: {
    marginTop: theme.spacing(1),
    textDecoration: 'underline',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  }
});

class Login extends Component {

  state = {
    email: "",
    password: "",    
    errors: {},      
  }

  componentDidMount() {
    //Si tiene un token guardado va al dashboard
    /*if(cookieManager.hasCookie('token')) {
      this.props.history.push('/dashboard');
    }*/
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });    
  };

  onSubmit = (ev) => {
    ev.preventDefault();

    new Promise(
        (resolve, reject) => {

          let errors = {};

          if(!this.state.email) {
            errors.errorTextEmail = "Por favor completa el campo";                  
          }
          else if(is.not.email(this.state.email)) {
            errors.errorTextEmail = "El campo debe ser un correo electrónico";                  
          }

          if(!this.state.password) {
            errors.errorTextPass = "Por favor completa el campo";                  
          }

          if(Object.keys(errors).length>0) {
            reject(errors);
          }

          resolve();
        }
    ).then(
      () => {
        console.log('Si resolvea',this.context.firebaseCtr);
        this.context.login(this.state.email,this.state.password);
        this.context.showLoader();
      }
    ).catch(
      (data) => {
        this.setState({errors:data});        
      }
    )
  }

  //showLoader = (show=true) => this.setState({showLoader:show})

  /*openModalWindow = (title,message) => {
    this.setState({modalWindowInfo:{title:title,message:message},showLoader:false});    
  }*/

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.mainCointainer}>    
        <img src={logo} className={classes.imgLogo} alt="logo" />
        <div className={classes.divContent}>
          <Paper className={classes.paper} elevation={1}>
              <Typography variant="h5" component="h3">
                Ingresa tus datos de acceso
              </Typography>     
              <form className={classes.formContainer}>                
                <FormControl>              
                  <TextField
                    autoComplete="useremail"
                    id="standard-email"
                    label="Correo electrónico"
                    error ={!!this.state.errors.errorTextEmail}
                    helperText={this.state.errors.errorTextEmail}
                    className={classes.textField}
                    value={this.state.email}
                    onChange={this.handleChange('email')}
                    margin="normal"
                  />
                </FormControl>
                <FormControl>              
                  <TextField
                    id="standard-password"
                    label="Contraseña"
                    error ={!!this.state.errors.errorTextPass}
                    helperText={this.state.errors.errorTextPass}
                    type="password"
                    autoComplete="current-password"
                    className={classes.textField}
                    value={this.state.password}
                    onChange={this.handleChange('password')}
                    margin="normal"
                  />                
                </FormControl>
                <Button type="submit" variant="contained" color="primary" className={classes.button} onClick={(ev) => this.onSubmit(ev)}>
                  Ingresar
                </Button>
                <div className={classes.createContainer}>
                  <div>ó</div>
                  <div className={classes.createAccountLink} onClick={(ev) => this.context.showSubScreen("CreateAccount")}>Crear cuenta</div>
                </div>
              </form>         
          </Paper>     
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default (withStyles(styles,{ withTheme: true })(withContext(Login)));
