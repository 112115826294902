import React, {Component} from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';

import clsx from 'clsx';

import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Divider from '@material-ui/core/Divider';


import { withContext } from '../context/AppContext';

const drawerWidth = 240;

const styles = theme => ({ 

    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
});

class DrawerBar extends Component {

    state = {drawerOpened:false}

    handleDrawerOpen = () => {
        this.setState({drawerOpened:true});
    };

    handleDrawerClose = () => {
        this.setState({drawerOpened:false});
    };

    getMenu = () => {
    
    }
    
    render() {
        const { classes,isSubMenu } = this.props;

        var open = this.state.drawerOpened;
        
        return (
            <React.Fragment>
                <AppBar
                    position="fixed"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: open,
                    })}
                >                    
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={() => {
                                this.handleDrawerOpen(); 
                            }}
                            edge="start"
                            className={clsx(classes.menuButton, open && classes.hide)}
                        >
                            <MenuIcon />
                        </IconButton>                    
                    
                        <Typography variant="h6" noWrap>
                            {this.props.title}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Drawer
                    className={classes.drawer}
                    onClose={this.handleDrawerClose}
                    anchor="left"
                    open={open}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <div className={classes.drawerHeader}>
                        <IconButton onClick={this.handleDrawerClose}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </div>
                    <Divider />
                    <List>
                        {this.props.menus.map((menu, index) => {
                        if(menu.tipo==="divisor") {
                            return <Divider key={'div'+index}/>
                        }

                        return <ListItem button key={menu.titulo} onClick={() => {
                            this.props.onClickMenu(menu)
                        }}>
                            <ListItemIcon>{menu.icono}</ListItemIcon>
                            <ListItemText primary={menu.titulo} />
                        </ListItem>
                        })}
                    </List>
                </Drawer>
            </React.Fragment>
        );
    }
}

DrawerBar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default (withStyles(styles,{ withTheme: true })(withContext(DrawerBar)));