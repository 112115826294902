import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

/*this.context.showDialog({title:"Error al intentar ingresar",message:msg,buttons:[
  {label:"Si",callback:this.props.closeEdition},
  {label:"No"}
]});*/


class DialogWindow extends React.Component {

  handleClose = (evt) => {
    //Si se pasa el parametro closeOnClick en false, es porque no se quiere que el popUp se pueda cerrar
    //dando clic fuera de la ventana en la parte transparente
    if(this.props.data && this.props.data.closeOnClick===false) return;
    this.props.closeModalWindow();
  }

  render() {
    console.log('pintar dialogo con',this.props.showDialogWindow);
    let showTitle = "";
    let dialogData = {title:'-',message:''};

    if(this.props.data)  {
      dialogData = this.props.data;
      showTitle = dialogData.title !== '' && dialogData.title !== undefined;
    }

    let buttons = null;

    if(dialogData.buttons!==undefined && dialogData.buttons.length>0) {
      buttons=[];

      for (var i = dialogData.buttons.length - 1; i >= 0; i--) {
        let currentCallback = this.handleClose;
        let modalRef = this;

        if(dialogData.buttons[i].callback) {
          currentCallback = (function() {
            modalRef.handleClose();
            this.callback(this.label);
          }).bind(dialogData.buttons[i])
        }

        buttons.unshift(<Button key={'butMod'+i} onClick={currentCallback} color="primary" autoFocus>{dialogData.buttons[i].label}</Button>);
      }
    }
    else {
      buttons=[<Button key='butMod1' onClick={this.handleClose} color="primary" autoFocus>{"OK"}</Button>];
    }

    if((dialogData.title==="" || dialogData.title===undefined) && (dialogData.message==="" || dialogData.message===undefined)) return null;

    return (
      <React.Fragment>
        <Dialog
          open={this.props.showDialogWindow}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {showTitle && <DialogTitle id="alert-dialog-title">{dialogData.title}</DialogTitle>}
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogData.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {buttons}
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default (DialogWindow);
