import React, {Component} from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';

import MomentUtils from '@date-io/moment';

import moment from 'moment/min/moment-with-locales';

import {Line} from 'react-chartjs-2';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import logo from '../res/img/logo.png'; // with import

import DrawerBar from '../components/DrawerBar';

import CloseSessionIcon from '@material-ui/icons/ExitToApp';

import { withContext } from '../context/AppContext';

import { tiposSecado } from '../Constants';



const styles = theme => ({ 

  mainCointainer: {
    display: "flex",
    flexDirection: "column",
    flex: "1",
    marginTop: theme.spacing(8),
  },

  topBar: {
    height: '200px',
    display: 'flex',
    padding: theme.spacing(2,2,0),
  },

  imgContainer: {
    display: 'flex',
    width: '200px',
    alignItems: 'center',
    marginLeft: theme.spacing(2),
  },

  controlsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
  },

  tipoProductoContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(8),
  },

  divContent: {
    display: "flex",
    padding: "20px",
    borderRadius: "4px",
    justifyContent: "center",
    //backgroundColor: "#eeeeee",    
    flex: 1,
  },

  paperTop: {
    ...theme.mixins.gutters(),
    padding: theme.spacing(4),    
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: '100%',
    margin: theme.spacing(2,4),
  },

  paperBottom: {
    ...theme.mixins.gutters(),
    padding: theme.spacing(4),    
    margin: theme.spacing(2,6),    
    height: '100%',
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: 'center',
  },

  imgLogo: {
    objectFit: "contain",
    width: '100%',    
    minHeight: '70px',
  },

  button: {
    marginTop: theme.spacing(3),
  }, 

  input: {
    padding: theme.spacing(1),
    minWidth: 200,
  },

  smallInput: {
    padding: theme.spacing(1),
    minWidth: 80,
    width: 80,
  },

  marginRight: {
    marginRight: theme.spacing(1),
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },

  borderDate: {
    minWidth: 145,
  },

  divLoader: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  circular: {
    outline: "0px solid transparent",   
  },

  titleEncontradas: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '-8px',
    marginBottom: '8px',
    fontSize: '1.4em',
  },

  cantEncontradas: {
    color: '#00107d',
    marginLeft: theme.spacing(1),
  }
});

var optionsChart = {
  scales: {
    yAxes: [{
      id: 'hum',
      type: 'linear',
      position: 'left',
      ticks: {
        //max: 100,
        //min: 0
        callback: function(value, index, values) {
          return value + "%";
        }
      },
      gridLines: {
        color: 'rgba(75,19,118,0.4)',
      }      
    }, {
      id: 'tem',
      type: 'linear',
      position: 'right',
      ticks: {
        //max: 100,
        //min: 0
        callback: function(value, index, values) {
          return value + " ºC";
        }
      },
      gridLines: {
        color: 'rgba(202,184,16,0.4)',
      },
      scaleLabel: {
        display: true,
      }
    }]
  },
  responsive: false,
  maintainAspectRatio: false,
  tooltips: {
    enabled: true,    
  }
}

var dataChart = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [
    {
      label: 'Humedad',
      fill: false,
      lineTension: 0.2,
      backgroundColor: 'rgba(75,19,118,0.9)',
      borderColor: 'rgba(19,19,118,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(19,19,118,1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 10,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(19,19,118,1)',
      pointHoverBorderColor: 'rgba(19,19,118,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 3,
      pointHitRadius: 10,
      yAxisID: 'hum',
      data: [65, 59, 80, 81, 56, 55, 40]
    },
    {
      label: 'Temperatura',
      fill: false,
      lineTension: 0.2,
      backgroundColor: 'rgba(202,184,16,1)',
      borderColor: 'rgba(202,184,16,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(202,184,16,1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 10,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(202,184,16,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 3,
      pointHitRadius: 10,
      yAxisID: 'tem',
      data: [75, 89, 40, 71, 56, 55, 40]
    }
  ],
};

const menus = [
  {
    titulo: 'Cerrar Sesión',
    icono: (<CloseSessionIcon />),
    accion: 'CloseSession',
    parametro: 'none',
  }
];

class Login extends Component {

  constructor(props) {
    super(props);
    console.log('Construye con',props);
    this.state = {
      tipoSecado: '',
      mostrarPor: 'medidas',   
      cantidad: 20,
      dateCalculated:false,
      initDate: moment().subtract(1,'month'),     
      endDate: new Date(),     
      chartWidth: 200,
      chartHeight: 200,
      ranChart: 'ranC',
      chartActive: false,
    }
  }

  componentDidMount() 
  {
    var chartHeight = document.getElementById("canvasPlace").clientHeight - 100;
    var chartWidth = document.getElementById("canvasPlace").clientWidth - (48*2);
    console.log('El h es',chartHeight);
    this.setState({chartWidth,chartHeight,ranChart:"dfg"});

    setTimeout(() => {
      this.setState({chartActive:true})
    }, 200);
  }

  obtenerData = (tipoSecado) => {

    console.log('propsData',this.props.userData);
    if(!this.props.userData) return null;

    var copyData = Object.values(this.props.userData);

    copyData = copyData.filter(value => value.s===tipoSecado);

    copyData.sort(function(a,b){
      return a.time - b.time;
    });

    //console.log('Ordered data',copyData);

    var finalData = [];

    console.log('Mostrar por',this.state.mostrarPor,this.state.cantidad);

    if(this.state.mostrarPor==="medidas") {
      for (var index = 0; index < copyData.length; index++) {
        finalData.push(copyData[index]);
        
        if(finalData.length===parseInt(this.state.cantidad)) {
          break;
        }
      }
    }
    else {
      finalData = copyData.filter((value) => {
        var dt = new Date();
        return moment(value.d).isBetween(this.state.initDate,this.state.endDate,"day","[]");
      });
    }

    var labels = [];
    var dataHumedad = [];
    var dataTemperatura = [];

    moment.locale('es-us');

    for (var index = 0; index < finalData.length; index++) {
      const element = finalData[index];
      labels.push(moment(element.d).locale('es-us').format("DD-MMM-YYYY HH:mm"));
      dataHumedad.push(element.h);
      dataTemperatura.push(element.t);
    }

    dataChart.labels = labels;
    dataChart.datasets[0].data = dataHumedad;
    dataChart.datasets[1].data = dataTemperatura;

    console.log('Termina con ',finalData);
    return finalData;


    /*Voy aca para listar los datos y poner a funcionar dateCalculated
    que lo que hace es que se deja como endDate el dia de la ultima medicion
    y como initDate la fecha de la medicion n-20*/
  }

  handleChangeText = name => event => {
    this.setState({ [name]: event.target.value });    
  };

  handleChangeSelect = (event) => {
    this.setState({ [event.target.name]: event.target.value });    
  };

  handleInitDateChange = date => {
    this.setState({initDate:date});
  };

  handleEndDateChange = date => {
    this.setState({endDate:date});
  };

  onClickMenu = (menu) => {
    console.log('Click en ',menu);

    switch(menu.accion) {
      case "CloseSession":
        this.context.closeSession();
        break;
      
      default:
        break;
    }
  }

  render() {
    const { classes } = this.props;

    console.log('Pinta con props',this.props);

    var tipoSecado = this.state.tipoSecado===""?this.props.lastTipo:this.state.tipoSecado;
    var data = this.obtenerData(tipoSecado);

    //console.log('Pinta con',this.state);

    return (
      <React.Fragment>
        <DrawerBar menus={menus} onClickMenu={this.onClickMenu}/>
        <div className={classes.mainCointainer}>    
          <div className={classes.topBar}>
            <div className={classes.imgContainer}>
              <img src={logo} className={classes.imgLogo} alt="logo" />
            </div>
            <Paper className={classes.paperTop}>

              <div className={classes.controlsContainer}>
                <div className={classes.tipoProductoContainer}>
                  <Typography variant="subtitle1" className={classes.marginRight}>
                    Tipo de producto:
                  </Typography>
                  <form className={classes.root} noValidate autoComplete="off">
                    <TextField id="tipo-producto" disabled value={this.props.userDetails.tipoFruto} label="" variant="outlined" inputProps={{
                        className: classes.input,                    
                    }}/>
                  </form>
                </div>
                <div className={classes.tipoProductoContainer}>
                  <Typography variant="subtitle1" className={classes.marginRight}>
                    Tipo de secado:
                  </Typography>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <Select
                      labelId="tipo-secado"
                      id="tipo-secado"
                      value={tipoSecado}
                      onChange={this.handleChangeSelect}   
                      name="tipoSecado"
                      inputProps={{
                        className: classes.input,                    
                      }}                   
                    >
                      {tiposSecado.map((value)=> {
                        return <MenuItem key={value} value={value}>{value}</MenuItem>
                      })}                      
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div className={classes.controlsContainer}>
                <div className={classes.tipoProductoContainer}>
                  <Typography variant="subtitle1" className={classes.marginRight}>
                    Mostrar por:
                  </Typography>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <Select
                      labelId="tipo-secado"
                      id="tipo-secado"
                      name="mostrarPor"
                      value={this.state.mostrarPor}
                      onChange={this.handleChangeSelect}   
                      inputProps={{
                        className: classes.input,                    
                      }}                   
                    >
                      <MenuItem name={"mostrarPor"} value={"medidas"}>Cantidad de medidas</MenuItem>
                      <MenuItem name={"mostrarPor"} value={"fechas"}>Rango de fechas</MenuItem>                      
                    </Select>
                  </FormControl>
                </div>

                {this.state.mostrarPor==="medidas" && <div className={classes.tipoProductoContainer}>
                  <Typography variant="subtitle1" className={classes.marginRight}>
                    Cantidad:
                  </Typography>
                  <form className={classes.root} noValidate autoComplete="off">
                    <TextField id="tipo-producto" type="number" value={this.state.cantidad} onChange={this.handleChangeText('cantidad')} variant="outlined" inputProps={{
                        className: classes.smallInput,                    
                    }}/>
                  </form>
                </div>}

                {this.state.mostrarPor==="fechas" && <React.Fragment>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <div className={classes.tipoProductoContainer}>
                      <Typography variant="subtitle1" className={classes.marginRight}>
                        Fecha Inicial:
                      </Typography>
                      <KeyboardDatePicker
                        format="MM/DD/YYYY"                        
                        id="date-picker-inline"                        
                        value={this.state.initDate}
                        onChange={this.handleInitDateChange}
                        className={classes.borderDate}
                        inputVariant="outlined"
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </div>            
                    <div className={classes.tipoProductoContainer}>
                      <Typography variant="subtitle1" className={classes.marginRight}>
                        Fecha Final:
                      </Typography>
                      <KeyboardDatePicker
                        format="MM/DD/YYYY"                        
                        id="date-picker-inline"                        
                        value={this.state.endDate}
                        onChange={this.handleEndDateChange}
                        className={classes.borderDate}
                        inputVariant="outlined"
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </div>        
                  </MuiPickersUtilsProvider>
                </React.Fragment>}
              </div>
            </Paper>
          </div>

          
          <Paper id="canvasPlace" className={classes.paperBottom} elevation={1}>
              {(!data || !this.state.chartActive || tipoSecado==="") && <div className={classes.divLoader}> 
                <CircularProgress size={50} className={classes.circular} />
              </div>} 
              {(data && data.length>0 && tipoSecado!=="") && this.state.chartActive && <React.Fragment>
                <div className={classes.titleEncontradas}>Medidas encontradas: <div className={classes.cantEncontradas}>{data.length}</div></div>
                <Line key={this.state.ranChart} data={dataChart} options={optionsChart} width={this.state.chartWidth} height={this.state.chartHeight} />
                </React.Fragment>}
              {(data && data.length===0) && <div style={{fontSize:'1.5em'}}>NO SE ENCUENTRAN DATOS QUE CUMPLAN LOS FILTROS.</div>}
          </Paper>             
        </div>      
      </React.Fragment>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default (withStyles(styles,{ withTheme: true })(withContext(Login)));
