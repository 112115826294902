import React from 'react';

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import { firebaseCtr } from './controllers/firebaseController';
import { AppContext } from './context/AppContext';

import ModalLoader from './components/ModalLoader';
import DialogWindow from './components/DialogWindow';

import Splash from './screens/Splash';
import Login from './screens/Login';
import CreateAccount from './screens/CreateAccount';
import Data from './screens/Data';
import DataVr2 from './screens/DataVr2';

import Fade from './components/Animation/Fade';

import Button from '@material-ui/core/Button';

import { tiposSecado } from './Constants';

import './App.css';

const appTheme = createMuiTheme({
  overrides: {
      palette: {
        primary: {
          width: '0% !important',
        }
      },

      MuiOutlinedInput: {
        input: {
          padding: 8
        },

        adornedEnd: {
          paddingRight: 0
        }
      }

  }
});

class App extends React.Component {
  state = {   
    inSplash:true,
    currentSubScreen:"", 
    showModalLoader:false,
    showDialogWindow:false,
    userData:undefined,
    userLotes:undefined,
    userDetails:{},
    lastTipo:'',
    dataModelVersion:1,
  };

  constructor(props) {
    super(props);

    this.firebaseCtr = firebaseCtr;

    setTimeout(() => {
      this.setState({inSplash:false})
    }, 2000);
  }

  async componentDidMount() {
    firebaseCtr.init(this);
  }

  showLoader = (show = true,loaderText='',callback=undefined) => {
    this.setState({showModalLoader:show,loaderText}, () => {
      if(callback!==undefined) callback();
    })
  }

  showDialog = (data) => {
    this.setState({DialogWindowData:data,showDialogWindow:true,showModalLoader:false});
  }

  showSubScreen = (screen) => {
      console.log("Mostrar",screen);
      //this.setState({currentSubScreen:"dataHistory"})
      this.setState({currentSubScreen:screen})
  }

  cerrarSubScreen = () => {
      this.setState({currentSubScreen:""})
  }

  login = (email,password) => {
    firebaseCtr.login(email,password,
      (dataLogin) => {
        console.log('Llega con ',dataLogin);
        if(dataLogin.error) {              
          var msg = dataLogin.errorCode;
          
          switch (dataLogin.errorCode) {
            case "auth/wrong-password":
              msg = "La contraseña es incorrecta";
              break;
  
            case "auth/user-not-found":
              msg = "No existe un usuario con ese correo electrónico";
              break;    
          
            default:
              msg = dataLogin.message;
              break;
          }
  
          this.showDialog({title:"Error al intentar ingresar",message:msg,buttons:[
            {label:"Continuar"}
            //{label:"Si",callback:this.props.closeEdition},
          ]});
        }  
  
        this.showLoader(false);    
      }
    )
  }

  closeSession = () => {
    firebaseCtr.closeSession();
  }

  authChange = async (user) => {      
    console.log('HUBO CAMBIO EN USER AUTH A ',user);
    if(user) {
      var userDetails = await firebaseCtr.getUserDetails(user);

      if(userDetails.dataModelVersion) {
        this.setState({dataModelVersion:Number(userDetails.dataModelVersion)})
        if(Number(userDetails.dataModelVersion)===2) {
          var userLotes = await firebaseCtr.getUserLotes(user);
          
          firebaseCtr.getUserData(user,(userData) => {
            console.log('userLotes',userLotes);
            console.log('userData',userData);

            //var lastTipo = tiposSecado[0];
                    
            if(Object.keys(userData).length>0) {
              var allData = Object.values(userData);
              allData.sort(function(a,b){
                return new Date(a.d) - new Date(b.d);
              });
              //console.log('Entra con initialData',allData);
              
              //console.log('Recupera el tipo de secado como ',lastTipo);          
            }
    
            console.log('Los datos',userData);
    
            this.setState({userLotes,userData});            
          });          
        }
      }
      //Flujo para la version 1 de la app
      else {
        console.log('userDetails',userDetails);
        
        firebaseCtr.getUserData(user,(userData) => {
          
          console.log('userData',userData);
  
          var lastTipo = tiposSecado[0];
                  
          if(Object.keys(userData).length>0) {
            var allData = Object.values(userData);
            allData.sort(function(a,b){
              return new Date(a.d) - new Date(b.d);
            });
            //console.log('Entra con initialData',allData);
            
            lastTipo = allData[allData.length-1].s;
            console.log('Recupera el tipo de secado como ',lastTipo);          
          }
  
          console.log('Los datos',userData);
  
          this.setState({userData,lastTipo});
        })
      }
    
      this.setState({currentSubScreen:"Data",userDetails});
    }
    else {
      //this.setState({currentSubScreen:"CreateAccount"});
      this.setState({currentSubScreen:"Login",userData:undefined,userDetails:{}});      
    }

    this.setState({user,inSplash:false},() => {});
  }

  render() {
    const {currentSubScreen,inSplash,dataModelVersion} = this.state;

    var classes = {};
    //<Fade show={this.state.showDialogWindow}>
    
    return (
      <AppContext.Provider value={this}>
        <CssBaseline />
        <MuiThemeProvider theme={appTheme}>
          <ModalLoader showModalLoader={this.state.showModalLoader} text={this.state.loaderText}/>      
          <DialogWindow showDialogWindow={this.state.showDialogWindow} data={this.state.DialogWindowData} closeModalWindow={() => {this.setState({showDialogWindow:false,DialogWindowData:{}})}}/>
          
          <Fade show={inSplash}>
            <Splash />
          </Fade>  
          
          {!inSplash && 
            <React.Fragment>
              {currentSubScreen==="Login" && <Login/>}
              {currentSubScreen==="CreateAccount" && <CreateAccount/>}
              {currentSubScreen==="Data" && dataModelVersion===1 && <Data userData={this.state.userData} userDetails={this.state.userDetails} lastTipo={this.state.lastTipo}/>}
              {currentSubScreen==="Data" && dataModelVersion===2 && <DataVr2 userData={this.state.userData} userDetails={this.state.userDetails} lastTipo={this.state.lastTipo} userLotes={this.state.userLotes}/>}
            </React.Fragment>
          }
        </MuiThemeProvider>
      </AppContext.Provider>
    );
  }
}

export default App;
