import React, {Component} from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import { tiposFruto } from '../Constants';

import logo from '../res/img/logo.png'; // with import

import { withContext } from '../context/AppContext';

var is = require('is_js');

//import FwModalLoader from './FwUtils/FwModalLoader';
//import FwModalWindow from './FwUtils/FwModalWindow';
//import AppBarAdmin from "./AppBarAdmin";


const styles = theme => ({ 

  mainCointainer: {
    display: "flex",
    flexDirection: "column",
    flex: "1",
  },
  
  divContent: {
    display: "flex",
    padding: "20px",
    borderRadius: "4px",
    justifyContent: "center",
    //backgroundColor: "#eeeeee",    
    flex: 1,
  },
  
  paper: {
    ...theme.mixins.gutters(),
    padding: theme.spacing(4),    
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minWidth: 350,
  },

  imgLogo: {
    objectFit: "contain",
    height: '10%',
    marginTop: '10%',
    minHeight: '70px',
  },

  formContainer: {
    marginTop: theme.spacing(),
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },

  button: {
    marginTop: theme.spacing(3),
  }, 

  createContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    fontSize: '1.2em',
    alignItems: 'center',
  },

  createAccountLink: {
    marginTop: theme.spacing(1),
    textDecoration: 'underline',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },

  typo: {
    marginTop: theme.spacing(2),
    color: 'rgba(0, 0, 0, 0.54)',
  },

  formControl: {
    marginTop: '16px',
    marginBottom: '8px',
  },
});

class CreateAccount extends Component {

  state = {
    email: "",
    nombre: "",
    password: "",    
    confirmPassword: "",    
    tipoFruto: "",
    errors: {},      
  }

  componentDidMount() {
    //Si tiene un token guardado va al dashboard
    /*if(cookieManager.hasCookie('token')) {
      this.props.history.push('/dashboard');
    }*/
  }
  
  handleChangeSelect = (event) => {
    console.log('El value es', event.target.value,);
    this.setState({ [event.target.name]: event.target.value });    
  };
  
  handleChange = name => event => {
    this.setState({ [name]: event.target.value });    
  };

  onSubmit = (ev) => {
    ev.preventDefault();

    new Promise(
        (resolve, reject) => {

          let errors = {
            errorTextEmail: "",
            errorTextName: "",
            errorTextPass: "",
            errorTextConfirmPass: "",
            errorProducto: "",
          };

          if(!this.state.email) {
            errors.errorTextEmail = "Por favor completa el campo";                  
          }
          else if(is.not.email(this.state.email)) {
            errors.errorTextEmail = "El campo debe ser un correo electrónico";                  
          }

          if(!this.state.nombre) {
            errors.errorTextName = "Por favor completa el campo";                  
          }
          
          if(!this.state.tipoFruto) {
            errors.errorProducto = "Por favor completa el campo";                  
          }

          if(!this.state.password) {
            errors.errorTextPass = "Por favor completa el campo";                  
          }

          if(!this.state.confirmPassword) {
            errors.errorTextConfirmPass = "Por favor completa el campo";                  
          }
          else if(this.state.password!==this.state.confirmPassword) {
            errors.errorTextConfirmPass = "La contraseña no coincide";
          }

          //Si tiene algun error
          if(Object.values(errors).filter(value => value!=="").length>0) {
            reject(errors);
          }

          resolve();
        }
    ).then(
      () => {
        console.log('Si resolvea',this.context.firebaseCtr);

        this.context.firebaseCtr.createAccount(this.state, (error) => {

          if(error) {          
            console.log('Error',error.code);
            this.context.showDialog({title:"Error al crear cuenta",message:error.message,buttons:[
              {label:"Continuar"}              
            ]});            
          }
          //Si pudo crear la cuenta entonces inicia sesion
          else {

            this.context.showDialog({title:"Creación de cuenta exitosa",message:"La cuenta ha sido creada exitosamente.",buttons:[
              {label:"Continuar"}              
            ]});     

            //this.context.login(this.state.email,this.state.password);            
            //console.log('Creación exitosa',);
            //this.setState({showLoader:false})
            //firebaseCtr.initSession(data.email,data.password) ;
          }
        })

        this.context.showLoader();

        /*this.context.firebaseCtr.login(this.state.email,this.state.password,
          (dataLogin) => {
            console.log('Llega con ',dataLogin);
            if(dataLogin.error) {              
              var msg = dataLogin.errorCode;
              
              switch (dataLogin.errorCode) {
                case "auth/wrong-password":
                  msg = "La contraseña es incorrecta";
                  break;

                case "auth/user-not-found":
                  msg = "No existe un usuario con ese correo electrónico";
                  break;    
              
                default:
                  msg = dataLogin.message;
                  break;
              }

              this.context.showDialog({title:"Error al intentar ingresar",message:msg,buttons:[
                {label:"Continuar"}
                //{label:"Si",callback:this.props.closeEdition},
              ]});
            }


            this.context.showLoader(false);    
          }
        );*/

        //this.context.showLoader();
      }
    ).catch(
      (data) => {
        this.setState({errors:data});        
      }
    )
  }

  //showLoader = (show=true) => this.setState({showLoader:show})

  /*openModalWindow = (title,message) => {
    this.setState({modalWindowInfo:{title:title,message:message},showLoader:false});    
  }*/

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.mainCointainer}>    
        <img src={logo} className={classes.imgLogo} alt="logo" />
        <div className={classes.divContent}>
          <Paper className={classes.paper} elevation={1}>
              <Typography variant="h5" component="h3">
                Creación de cuenta
              </Typography>     
              <form className={classes.formContainer}>                
                <FormControl>              
                  <TextField
                    autoComplete="email"
                    id="standard-email"
                    label="Correo electrónico"
                    error ={!!this.state.errors.errorTextEmail}
                    helperText={this.state.errors.errorTextEmail}
                    className={classes.textField}
                    value={this.state.email}
                    onChange={this.handleChange('email')}
                    margin="normal"
                  />
                </FormControl>
                <FormControl>              
                  <TextField
                    autoComplete="name"
                    id="standard-name"
                    label="Nombre completo"
                    error ={!!this.state.errors.errorTextName}
                    helperText={this.state.errors.errorTextName}
                    className={classes.textField}
                    value={this.state.nombre}
                    onChange={this.handleChange('nombre')}
                    margin="normal"
                  />
                </FormControl>                
                <FormControl  className={classes.formControl} error ={!!this.state.errors.errorProducto}>
                  <InputLabel id="demo-simple-select-label">Tipo de producto</InputLabel>
                  <Select
                    labelId="tipo-producto"
                    id="tipo-producto"
                    value={this.state.tipoFruto}
                    onChange={this.handleChangeSelect}   
                    name="tipoFruto"
                    inputProps={{
                      className: classes.input,                    
                    }}                   
                  >
                    {tiposFruto.map((value)=> {
                      return <MenuItem key={value} value={value}>{value}</MenuItem>
                    })}                      
                  </Select>
                  {!!this.state.errors.errorProducto && <FormHelperText>{this.state.errors.errorProducto}</FormHelperText>}
                </FormControl>                
                <FormControl>              
                  <TextField
                    id="standard-password"
                    label="Contraseña"
                    error ={!!this.state.errors.errorTextPass}
                    helperText={this.state.errors.errorTextPass}
                    type="password"
                    autoComplete="current-password"
                    className={classes.textField}
                    value={this.state.password}
                    onChange={this.handleChange('password')}
                    margin="normal"
                  />                
                </FormControl>
                <FormControl>              
                  <TextField
                    id="standard-confirm-password"
                    label="Confirmación de contraseña"
                    error ={!!this.state.errors.errorTextConfirmPass}
                    helperText={this.state.errors.errorTextConfirmPass}
                    type="password"
                    autoComplete="current-confirm-password"
                    className={classes.textField}
                    value={this.state.confirmPassword}
                    onChange={this.handleChange('confirmPassword')}
                    margin="normal"
                  />                
                </FormControl>
                <Button type="submit" variant="contained" color="primary" className={classes.button} onClick={(ev) => this.onSubmit(ev)}>
                  Crear Cuenta
                </Button>
                <div className={classes.createContainer}>
                  <div>ó</div>
                  <div className={classes.createAccountLink} onClick={(ev) => this.context.showSubScreen("Login")}>¿Ya tienes cuenta?</div>
                </div>
              </form>         
          </Paper>     
        </div>
      </div>
    );
  }
}

CreateAccount.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default (withStyles(styles,{ withTheme: true })(withContext(CreateAccount)));
